// eslint-disable-next-line import/prefer-default-export
export const tableFee = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'skill_name',
    label: 'Posisi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'description',
    label: 'Deskripsi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'admin_fee',
    label: 'Biaya Standar',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '82px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]

export const tableDiscount = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'minimum_total_talent',
    label: 'Jumlah Talent Minimal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'admin_fee_discount_value',
    label: 'Biaya Potongan',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
]
