<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2">Biaya Admin</div>
    <BCol class="p-0 d-flex gap-10">
      <BDropdown
          id="dropdown-1"
          class="filter"
          no-caret variant="primary"
          toggle-class="text-decoration-none toggle-custom button-primary rounded-lg py-0 px-1"
        >
          <h5>Filter</h5>
          <template #button-content>
              <BImg src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg" class="height-[20px]"/>
          </template>
          <BDropdown id="dropdown-2" class="w-100" toggle-class="text-left" variant="none" left no-caret dropright>
            <template #button-content>
                <span class="hover-salmon text-6 w-100">Skill role</span>
            </template>
            <BDropdownForm style="width: 200px">
              <BFormCheckbox
                v-model="filterSelected"
                :name="`checkbox-${index}`"
                class="text-6 mb-1"
                :value="skill.id"
                v-for="(skill, index) in listSkills"
                :key="index"
                @change="getListData()"
              >
                {{skill.name}}
              </BFormCheckbox>
            </BDropdownForm>
          </BDropdown>
          <h5 class="pb-0 pt-1 font-bold text-danger w-100 cursor-pointer" @click="resetFilter()">Reset filter</h5>
        </BDropdown>
      <div class="d-flex rounded-lg align-items-center border search-bar">
        <span
          class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Cari Nama"
          @input="handleSearch()"
          class="border-0"
        />
      </div>
      <BButton
        tag="router-link"
        :to="{name: $route.meta.routeCreate}"
        variant="primary"
        class="ml-auto px-3"
      >
        <strong>Tambah</strong>
      </BButton>
    </BCol>

    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 275px); overflow-y: scroll"
        class="mt-[24px]"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-info"
                @click="toDescription(data.item.admin_fee_discounts)"
                v-b-modal.modalDetail
                :description="dataDescription"
              >
                <span class="k-search-normal-1 h-100 font-bold text-10 align-middle"></span>
              </BButton>
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                :to="{
                  name: $route.meta.routeEdit,
                  params: { id: data.item.id }
                }"
                tag="router-link"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                  alt="Komerce"
                >
              </BButton>
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning">
                <img
                  v-b-modal.modalDelete
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                  alt="Komerce"
                  @click="selectItem(data.item.id)"
                >
              </BButton>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>

    <ModalDelete :id-item="idItem" @deleted="getListData()" />
    <ModalDetail :description="dataDescription" />
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { tableFee } from './config'
import ModalDelete from './ModalDelete.vue'
import ModalDetail from './ModalDetail.vue'

export default {
  components: {
    ModalDelete,
    ModalDetail,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableFee,
      idItem: 0,
      initialName: '',
      detailDivision: {},
      alertError,
      alertSuccess,
      dataDescription: [],
      filterSelected: [],
      listSkills: [],
    }
  },
  mounted() {
    this.getListData()
    this.getListSkills()
  },
  computed: {
    skill() { return this.filterSelected.join(',') },
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}&skill=${this.skill}`
      const url = `v1/admin_fees?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}&skill=${this.skill}`
        const url = `v1/admin_fees?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=2`
      const url = `v1/skills/resource?${params}`

      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkills = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id, edit) {
      if (!edit) this.idItem = id
      else {
        this.idItem = id
        this.getDetailDivision(id)
      }
    },
    toDescription(desc) {
      this.dataDescription = desc
    },
  },
}
</script>
