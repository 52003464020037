<template>
  <BModal
    id="modalDetail"
    ref="modalDetail"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="lg"
    :centered="true"
    body-class="p-0"
  >
    <div class="text-left d-flex flex-column ">
      <div class="font-bold text-8 text-black bg-gray py-[16px] px-[25px]">
        Detail
      </div>
      <b-col md="12" class="p-0 mb-2">
        <div class="d-flex flex-column">
          <span class="px-[25px] py-[16px] border-b border-t" v-for="desc in description" :key="desc.id">
            {{desc.description}}
          </span>
        </div>
      </b-col>
      <div class="d-flex gap-12 justify-center px-[25px] py-[16px]">
        <button
          size="md"
          class="w-100 button-primary py-[8px]"
          @click="$bvModal.hide('modalDetail')"
        >
          Tutup
        </button>
      </div>
    </div>
  </BModal>
</template>

<script>
export default {
  props: {
    description: Array,
  },
}
</script>

<style scoped>
.bg-gray {
  background-color: #F4F4F4;
}
</style>
